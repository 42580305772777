<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="赠送类型" prop="signType">
                <a-select placeholder="请选择类型" v-model="queryParam.signType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.sign_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="获得钻石数量" prop="gold">-->
<!--                <a-input v-model="queryParam.gold" placeholder="请输入获得钻石数量" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->

              <a-col :md="6" :sm="24">
                <a-form-item label="签到天数" prop="dayTitle">
                  <a-input v-model="queryParam.dayTitle" placeholder="请输入签到天数" allow-clear/>
                </a-form-item>
              </a-col>
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="宝箱最小钻石" prop="minGold">-->
<!--                  <a-input v-model="queryParam.minGold" placeholder="请输入宝箱最小钻石" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="宝箱最大钻石" prop="maxGold">-->
<!--                  <a-input v-model="queryParam.maxGold" placeholder="请输入宝箱最大钻石" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="6" :sm="24">
                <a-form-item label="是否是会员" prop="vipFlag">
                  <a-select placeholder="请选择是否是会员" v-model="queryParam.vipFlag" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_yes_no_number" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:signInRule:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:signInRule:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:signInRule:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:signInRule:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :signTypeOptions="dict.type.sign_type"
        :vipFlagOptions="dict.type.sys_yes_no_number"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <!--        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"

        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="signType" slot-scope="text, record">
          <dict-tag :options="dict.type['sign_type']" :value="record.signType"/>
        </span>
        <span slot="vipFlag" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.vipFlag"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['biz:signInRule:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:signInRule:edit']">
            <a-icon type="edit" />修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['biz:signInRule:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:signInRule:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSignInRule,listSignInRule, delSignInRule } from '@/api/biz/signInRule'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'SignInRule',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sign_type', 'sys_yes_no_number'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        signType: null,
        gold: null,
        dayTitle: null,
        minGold: null,
        maxGold: null,
        vipFlag: null,
        sort: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '赠送类型',
          dataIndex: 'signType',
          scopedSlots: { customRender: 'signType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '钻石数量',
          dataIndex: 'gold',
          ellipsis: true,
          align: 'center',
          customRender:function (t,r,index) {
            if(r.signType==1){
              return t;
            }else{
              return "-";
            }
          }
        },
        {
          title: '道具名称',
          dataIndex: 'gold',
          ellipsis: true,
          align: 'toolsName',
          customRender:function (t,r,index) {
            if(r.signType==2){
              return r.toolsName;
            }else{
              return "-";
            }
          }
        },
        {
          title: '签到天数',
          dataIndex: 'dayTitle',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '宝箱最小钻石',
        //   dataIndex: 'minGold',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '宝箱最大钻石',
        //   dataIndex: 'maxGold',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '是否是会员',
          dataIndex: 'vipFlag',
          scopedSlots: { customRender: 'vipFlag' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询签到配置列表 */
    getList () {
      this.loading = true
     pageSignInRule(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        signType: undefined,
        gold: undefined,
        dayTitle: undefined,
        minGold: undefined,
        maxGold: undefined,
        vipFlag: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSignInRule(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/signInRule/export', {
            ...that.queryParam
          }, `签到配置_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
